export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "PLACE_HOLDERS": {
          
        },
        "LABELS": {
          "RANGE_DATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de Fecha"])},
          "PORCENTAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje"])},
          "ADVANCE_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda avanzada"])}
        },
        "BUTTONS": {
          "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
          "BILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturar"])}
        },
        "TOOLTIPS": {
          
        },
        "MESSAGES": {
          "INDICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes filtrar por un rango de fecha"])},
          "NO_DATA_PRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontrarón ventas disponibles a facturar"])},
          "TITLE_BILL_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura creada"])}
        }
      }
    }
  })
}
