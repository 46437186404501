
import { computed, defineComponent, onMounted, ref, watch, onUnmounted } from "vue";
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import InputText from "@/components/forms/InputText.vue"
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import { Form } from "vee-validate";
import { getModule } from "vuex-module-decorators";
import moduleInfo from '@/store/modules/bill/modules/info-bill-global'
import {formatNumeric} from "@/core/shared/functions/Formatter"
import NewBillModule from "@/store/modules/bill/modules/new-bill";
import emitter from "@/core/emitters";
import Loading from "@/components/shared/Loading.vue";
import FieldInfo from "@/views/billing/products/components/FieldInfo.vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SwalMessageService from "@/core/services/SwalMessageService";
import { SwalIconType } from "@/core/enums/swal-icon-type";
import router from "@/router";
import { RouteNames } from "@/router/route-names";
import FiltersBillGlobal from "@/models/bill/filters/FilterBillGlobal";

export default defineComponent({
    components:{
        Form
        , InputText
        , DatePickerRange
        , Loading
        , FieldInfo
    }
    , setup(){
        const moduleForm = getModule(NewBillModule);
        const form = ref();
        const submit = ref<HTMLButtonElement>();

         /**llamado del llenado de los selects */
        /*moduleForm.getComboWayToPay();
        moduleForm.getComboUseCfdi();
        moduleForm.getComboPaymentTypes();
        moduleForm.getComboRegimes();*/

        //METHODS
        const searchGlobalBill = () => submit.value?.click();
        const callSubmit = (data: FiltersBillGlobal ) => moduleInfo.UPDATE_FILTERS(data)
    

        //PROPS AND STORE


        const usePorcentage = computed(() => moduleInfo.usePorcentageFilter);

       
        const loading = computed(() => moduleInfo.loading)
        const haveInfo = computed(() => moduleInfo.haveInfo)
        const infoGlobal = computed(() => moduleInfo.getInfoGlobal)

        watch(infoGlobal, (newValue) => { 
            if (newValue && newValue.total_realy > 0) { 
                moduleForm.SET_AMOUNT_TO_BILL(usePorcentage.value ? newValue.total : newValue.total_realy);
            }
        })
        const messageService = new SwalMessageService();

        onMounted(() => {
            emitter.on('showBillCreated', (payload) => {
                //mostrar la opcion para abrir la factura en el modulo de creadas
                messageService.confirm(SwalIconType.Success, 'Exito al crear la factura. <br> ¿Desea ir a ver la factura creada para poder descargar sus archivos?')
                .then(isYes => {
                    //enviar mediante el route a la pagina facturas creadas mediante el numBill
                    if (isYes) {
                        router.push({
                            query: {
                                numBill: payload?.billFolio
                            }
                            , name: RouteNames.BillCreated
                        })
                    }

                })
            })
            setCurrentPageBreadcrumbs("Facturación Global", ["Módulos", "Facturación"]);
            moduleForm.SET_IS_BILLING_GLOBAL(true);
        })
        onUnmounted(() => { 
            moduleInfo.RESET_GLOBAL_INFO();
        })
        const billGlobal = () => {
            //facturacion global
            moduleInfo.StampBillGlobal();
        }

        return {
            InputTextMaskTypes
            , searchGlobalBill
            , usePorcentage
            , callSubmit
            , form
            , submit
            , formatNumeric
            , maskNumber: InputTextMaskTypes.Money
            , loading
            , haveInfo
            , infoGlobal
            , billGlobal
        }
    }
})
