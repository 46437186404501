import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import InfoBillGlobalState from '../../models/InfoBillGlobalState';
import FiltersBillGlobal from '@/models/bill/filters/FilterBillGlobal';
import GlobalBillInfo from '@/models/bill/GlobalBillInfo';
import TableSalesToBillService from '@/core/services/bill/interfaces/TableSalesToBillService';
import BillService from '@/core/services/bill/BillService';

enum MutationsLocal { 
    RESET_GLOBAL_INFO = "RESET_GLOBAL_INFO"
    , SET_GLOBAL_INFO = "SET_GLOBAL_INFO"
}
enum ActionsLocal { 
    SEARCH_INFO_BILL_GLOBAL = "SEARCH_INFO_BILL_GLOBAL"
}
@Module({ dynamic: true, store, namespaced: true, name: Modules.InfoBillGlobal })
class InfoBillGlobal extends VuexModule implements InfoBillGlobalState {
    infoGlobal: GlobalBillInfo | null = null;
    loading = false;
    filters = {
        porcentage: ''
    } as FiltersBillGlobal;

    get haveInfo(): boolean {
        return this.infoGlobal != null;
    }

    get getInfoGlobal(): GlobalBillInfo | null {
        return this.infoGlobal;
    }
    get usePorcentageFilter() {
        return (this.filters?.porcentage ?? '').length > 0;
    }
    
    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
 
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersBillGlobal>){
        SaveValuesToStore(this.filters, filters, true);
    }
    @Mutation
    [MutationsLocal.RESET_GLOBAL_INFO](){
        this.infoGlobal = null;
    }
     @Mutation
    [MutationsLocal.SET_GLOBAL_INFO](value: GlobalBillInfo){
        this.infoGlobal = value;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [ActionsLocal.SEARCH_INFO_BILL_GLOBAL](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         
         const resp = (await (serviceBill.searchInfoBillGlobal(this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ?? 
             {} as GlobalBillInfo
         
         if (resp) {
             this.context.commit(MutationsLocal.SET_GLOBAL_INFO, resp);
         }  else this.context.commit(MutationsLocal.RESET_GLOBAL_INFO);
       
    }
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersBillGlobal>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.dispatch(ActionsLocal.SEARCH_INFO_BILL_GLOBAL);
    }
    
    /**
     * Manda a llamar la facturacion global.
     */
    @Action
    async StampBillGlobal() { 
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        const resp = (await (serviceBill.stampBillGlobalAsync(this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ?? 
             {} as GlobalBillInfo
         
        if (resp) {
            this.context.commit(MutationsLocal.RESET_GLOBAL_INFO);
        }
    }
} 

const serviceBill: TableSalesToBillService = new BillService();

export default getModule(InfoBillGlobal);